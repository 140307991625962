// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-page-components-index-page-index-page-jsx": () => import("C:\\ox\\deskpro-and-more\\d-gatsby\\dam\\src\\pageComponents\\indexPage\\indexPage.jsx" /* webpackChunkName: "component---src-page-components-index-page-index-page-jsx" */),
  "component---src-page-components-deskpro-app-deskpro-app-jsx": () => import("C:\\ox\\deskpro-and-more\\d-gatsby\\dam\\src\\pageComponents\\deskproApp\\deskproApp.jsx" /* webpackChunkName: "component---src-page-components-deskpro-app-deskpro-app-jsx" */),
  "component---src-pages-404-jsx": () => import("C:\\ox\\deskpro-and-more\\d-gatsby\\dam\\src\\pages\\404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\ox\\deskpro-and-more\\d-gatsby\\dam\\.cache\\data.json")

